import React, { useEffect, useState } from 'react'
import { getSelectedPlan } from '../../APIs/APIFunctions'
import styled from 'styled-components';
import PlanCard from './PlanCard';
import Button from '../../Common/Button';
import { useLocation, useNavigate } from 'react-router-dom';

const StyledPlan = styled.div`
  h2{
    font-size: 2rem;
  }
  p{
    text-align: center;
    font-weight: 600;
    color: var(--blue);
    margin: 8px 0 24px 0;
  }
  button{
    background: #d91817;
    margin-top: 40px;
  }
`

const SelectedPlan = () => {

  const [subDetails, setSubDetails] = useState('');

  const navigate = useNavigate();
  const location = useLocation();

  async function handlePlan() {
    const data = await getSelectedPlan();
    console.log(data);
    setSubDetails(prev => data);
  }

  function handleNavigate(e) {
    e.preventDefault();
    sessionStorage.removeItem('dmt');
    sessionStorage.removeItem('aid');
    navigate('/');
  }

  useEffect(()=>{
    handlePlan();
  },[])

  return (
    <StyledPlan>
      <h2 className='blueHeading italic'> Great Choice </h2>
      <p> Your payment and plan <br/> purchase is successful </p>
      { subDetails.length != 0 ? 
        <PlanCard 
          name={subDetails.data.msg.application_entity_relationship_subscription.entity_data.name}
          subId={subDetails.data.msg.application_entity_relationship_subscription.entity_data.id}
          price={subDetails.data.msg.application_entity_relationship_subscription.entity_data.amount}
          interest={subDetails.data.msg.application_entity_relationship_subscription.entity_data.entity_details.entity_details.interest}
          start={subDetails.data.msg.application_entity_relationship_subscription.start_date}
          end={subDetails.data.msg.application_entity_relationship_subscription.end_date}
        />
        : <></>
      }
      <Button onClick={handleNavigate}> Exit </Button>
    </StyledPlan>
  )
}

export default SelectedPlan
