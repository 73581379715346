import React from 'react'
import styled from 'styled-components'

const StyledCard = styled.div`
  background: linear-gradient(135deg, rgba(1,27,101,1) 40%, rgba(102,4,185,1) 100%);
  padding: 16px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 32px;
  position:relative;
  .best{
    right: 0!important;
  }
  .tenure{
    display:flex;
    align-items:center;
    justify-content:space-between;
  }
  h2{
    color:#fff302;
  }
  h3{
    font-weight: 500;
  }
  h6{
    font-weight: 400;
    text-align:right;
  }
  *{
    color:white; 
  }
`

const PlanCard = (props) => {
  return (
    <StyledCard>
      { props.plan == "PLTM" ? <span className='best'> Best Value </span> : <></> }
      <div>
        <h3> Get Medical Loan </h3>
        <h2> @{props.interestNum}%* Interest </h2>
      </div>
      <div className='tenure'>
        <div>
          12 Months
        </div>
        <div>
          <h2>₹{props.amount}</h2>
          <h6> {Math.round(Number(props.amount)/12)}/month </h6>
        </div>
      </div>
    </StyledCard>
  )
}

export default PlanCard
