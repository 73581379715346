import React from "react";
import styled from "styled-components";
import loading from "./../../Assets/Common/loading.svg"

const StyledButton = styled.button`
  background: var(--blue);
  color: white!important;
  border: none;
  padding: 8px 16px;
  width: 100%;
  border-radius: 100px;
  font-weight: 600;
  font-size: 1rem;
  &:disabled{
    background:#e2e2e2;
    color: #a7a7a7;
  }
  .loading{
    animation-name: rotate;
    animation-duration: 0.6s;
    animation-iteration-count: infinite;
  }
  @keyframes rotate {
    from{
      rotate: 0deg;
    }
    to{
      rotate: 360deg;
    }
  }
`;

const Button = (props) => {
  return (
    <StyledButton onClick={props.onClick} type={props.type} disabled={props.disabled}>
      { props.loading ? <img src={loading} alt="" className="loading" /> : props.children }
    </StyledButton>
  );
};

export default Button;
