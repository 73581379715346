import React, { useEffect } from 'react'
import OTPForm from '../Forms/OTPForm'
import { useLocation, useNavigate } from 'react-router-dom'
import editIcon from './../../Assets/Common/editIcon.svg'
import medicalLoan from '../../Assets/Common/medicalLoans.png'
import styled from 'styled-components'

const StyledOTP = styled.div`
  *{
    color: #666666;
  }
  > img{
    margin: auto;
    display:block;
    margin-bottom: 16px;
  }
`

const OTP = () => {

  const location = useLocation();
  const navigate = useNavigate();
  
  useEffect(()=>{
    if (location.state == null) {
      navigate('/');
    }
  },[])

  return (
    <StyledOTP className='paddingHor'>
      <img src={medicalLoan} alt="medical loans at 5%" width="300" />
      <div style={{padding: "0 20px"}}>
        <h3> We've sent an OTP </h3>
        <h5> 
          to mobile number { location.state != null ? location.state.number : <></> } 
          <img src={editIcon} alt='edit mobile number' onClick={()=>{ navigate('/') }} />
        </h5>
        <OTPForm/>
      </div>
    </StyledOTP>
  )
}

export default OTP