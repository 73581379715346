import React from 'react'
import styled from 'styled-components'

const StyledRadio = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  position:relative;
  label{
    background: #d4ebfd;
    color:#1b3c6e;
    width: 100%;
    font-weight: 600;
    padding: 8px 8px 8px 24px;
    display:inline-block;
    border-radius: 8px;
  }
  input{
    margin-right: -20px;
    position:relative;
    z-index:1;
    accent-color:#fff302;
  }
  input:checked + label{
    background: linear-gradient(135deg, rgba(1,27,101,1) 40%, rgba(102,4,185,1) 100%);
    color:white
  }
`

const SelectPlanRadios = (props) => {

  return (
    <>
      {
        props.data.map((value, index)=>{
          return <StyledRadio key={value.entity_code + index} className='singleOption'> 
            { value.entity_code == "PLTM" ? <span className='best'> Best Value </span> : <></> }
            <input onChange={(e) => { return e.target.checked ? props.changeActive(e.target.value) : '' }} 
              defaultChecked={ value.entity_code == props.activePlan ? true : false } 
              type='radio' 
              name='plans' 
              value={value.entity_code}
              id={value.entity_code}
              aid={value.id}
              amount={value.amount}
              /> 
              <label htmlFor={value.entity_code}> {value.name} 
          </label>
          </StyledRadio>
        })
      }
    </>
  )
}

export default SelectPlanRadios
