import React, { useState } from 'react'
import styled from 'styled-components'
import LoanCard from './LoanCard'
import instantImage from '../../Assets/Common/instantLoan.png';
import approvedImage from '../../Assets/Common/approvedLoan.png';
import Button from '../Common/Button';

const StyledLoanType = styled.div`
  position:relative;
  margin: 16px 0;
  h2{
    color: #1b3d6e;
    text-align:center;
    font-style: italic;
  }
  > p{
    color: var(--blue);
    font-weight: 600;
    text-align:center;
    margin-bottom: 32px;
    line-height: 135%;
  }
  .loanCard:not(:last-child){
    margin-bottom: 24px;
  }
  .overlay{
    position: absolute;
    background: rgba(0, 0, 0, 0.3);
    width: 100%;
    height: 100%;
    display: none;
    z-index: 1;
    align-items: center;
    justify-content: center;
    border-radius: 16px;
  }
  .overlay > div{
    background:white;
    width: 90%;
    padding: 16px;
    border-radius: 8px;
  }
  .overlay p{
    font-size: 0.9rem;
    font-weight: 600;
    line-height: 124%;
    margin: 8px 0;
  }
  .overlay h3{ 
    color: #1b3c6e;
  }
  .overlay button{
    width: auto;
    padding: 8px 24px;
    margin: auto;
    display:block; 
  }
`

const LoanType = () => {

  const [info, setInfo] = useState('approved');

  return (
    <StyledLoanType className='loanWrapper'>
      <div className='overlay'>
        <div>
          { info == 'approved' ? <> 
          <h3> Pre-Approved Medical Loan </h3>
          <p>
            Get access to money whenever you need it. You can borrow up to your set limit, repay it, and borrow again, paying interest only on what you use.
          </p>
           </> : <>  </> }

           { info == 'instant' ? <> 
          <h3> Instant Medical Loan </h3>
          <p>
            Get access to instant cash for your emergency medical needs. Apply online, get approved in minutes, and use the funds for any urgent medical expenses.
          </p>
           </> : <>  </> }

          <Button onClick={ ()=>{ document.querySelector('.loanWrapper .overlay').style.display = 'none'; } } > OK </Button>
        </div>
      </div>
      
      <h2>
        Welcome To Ayushpay!
      </h2>
      <p> Choose the type of medical loan you need </p>
      
      <LoanCard 
        image={instantImage} 
        path="/welcome/approved" 
        buttonText="Apply for instant medical loan" 
        showInfo={setInfo}
        name="approved"
        />
      
      <LoanCard 
        image={approvedImage} 
        path="/welcome/approved" 
        buttonText="Apply for Pre-Approved medical loan" 
        showInfo={setInfo}
        name="instant"
        />
    </StyledLoanType>
  )
}

export default LoanType