import React from 'react'
import styled from 'styled-components'
import SelectPlanOptions from './SelectPlanOptions'

const StyledSelect = styled.div`

`

const SelectPlan = () => {
  return (
    <StyledSelect>
      <h2 className='blueHeading italic'> Buy the best plan <br/> per your need </h2>
      <SelectPlanOptions/>
    </StyledSelect>
  )
}

export default SelectPlan
