import React from 'react'
import styled from 'styled-components'

const StyledPlanCard = styled.div`
  background: linear-gradient(135deg, rgba(1,27,101,1) 40%, rgba(102,4,185,1) 100%);
  border-radius: 16px;
  *{
    color:white;
  }
  .title{
    padding: 16px;
    border-bottom: 1px solid lightgrey;
    font-weight: 600;
  }
  .details{
    padding: 16px;
    font-weight: 500;
  }
  .title{
    display:flex;
    justify-content:space-between;
  }
  .details{
    display:grid;
    grid-template-columns: 1fr 1fr;
    gap: 8px;
  }
`

const PlanCard = (props) => {
  return (
    <StyledPlanCard>
      <div className='title'>
        <div>
          Plan
        </div>
        <div>
          {props.name}
        </div>
      </div>
      <div className='details'>
        <div> Subscription Id </div> <div> {props.subId} </div>
        <div> Start Date </div> <div> {props.start} </div>
        <div> End Date </div> <div> {props.end} </div>
        <div> Plan Price </div> <div> ₹{props.price} </div>
        <div> Interest Rate </div> <div> {props.interest}% </div>
      </div>
    </StyledPlanCard>
  )
}

export default PlanCard
