import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Button from "../Common/Button";
import { checkEligibility, getApplicationDetails } from '../APIs/APIFunctions';
import { useNavigate } from 'react-router-dom';

const StyledPreApprovedForm = styled.form`
  padding: 0 16px;
  margin: 16px 0;
  select{
    color: #757575;
  }
  > *:not(:last-child){
    margin-bottom: 16px;
  }
`

function checkLength(value) {
  return value.value != 0
}

const PreApprovedForm = () => {

  const [employee, setEmployee] = useState();
  const [btnState, setBtnState] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);

  const navigate = useNavigate();

  async function handleSubmit(e) {
    e.preventDefault();
    setBtnLoading(prev => true);

    // console.log(e.target.elements.credit.value.length)

    let employeeType = e.target.elements.employment.value;
    let salaryMedium = e.target.elements.credit == undefined ? "" : e.target.elements.credit.value;
    let monthlyIncome = e.target.elements.income == undefined ? "": e.target.elements.income.value;
    let pan = e.target.elements.pan == undefined ? "" : e.target.elements.pan.value ;
    let pincode = e.target.elements.pincode.value;
    let firstname = e.target.elements.firstname.value;
    let lastname = e.target.elements.lastname.value;
    let dob = e.target.elements.dob.value;

    console.log(employeeType,salaryMedium, monthlyIncome, pan, pincode);
    try {
      const data = await checkEligibility(employeeType,salaryMedium, monthlyIncome, pan, pincode, firstname, lastname, dob)
      console.log(data);
      navigate('/welcome/checkEligibility');
    } catch (error) {
      console.log(error);
    } finally {
      setBtnLoading(prev => false);
    }
  }

  function handleChange(e) {
    if (e.target.id == 'employment') {
      setEmployee(prev => e.target.value);
    }
    if(Array.from(document.querySelectorAll('.eligibilityCheck')).every(checkLength)){
      setBtnState(prev => false);
      document.querySelectorAll('.eligibilityCheck').forEach((value, index)=>{
        console.log('checking');
      })
    } else{
      setBtnState(prev => true);
    }
  }

  useEffect(()=>{
    if(Array.from(document.querySelectorAll('.eligibilityCheck')).every(checkLength)){
      setBtnState(prev => false);
      document.querySelectorAll('.eligibilityCheck').forEach((value, index)=>{
        console.log('checking');
      })
    } else{
      setBtnState(prev => true);
    }
  }, [employee])

  return (
    <StyledPreApprovedForm onSubmit={handleSubmit}>
      <input onChange={handleChange} className='eligibilityCheck' type="text" required placeholder='First Name*' name='firstname' id='firstname' />
      <input onChange={handleChange} className='eligibilityCheck' type="text" required placeholder='Last Name*' name='lastname' id='lastname' />
      <input onChange={handleChange} className='eligibilityCheck' type="date" required placeholder='Date of Birth*' name='dob' id='dob' />
      <input onChange={handleChange} className='eligibilityCheck' type="text" maxLength="10" required placeholder='PAN Number*' name='pan' id='pan' />
      <input onChange={handleChange} className='eligibilityCheck' type="text" placeholder='Pin Code*' name='pincode' id='pincode' />
      <select className='eligibilityCheck' required defaultValue="" name='employment' id='employment' onChange={handleChange}>
        <option value=""  disabled hidden>Employement Type*</option>
        <option value="e"> Self Employed </option>
        <option value="s"> Full Time Employee </option>
        <option value="p"> Unemployed </option>
      </select>

      {employee == 'e' || employee == 's' ? <input onChange={handleChange} className='eligibilityCheck' required type="text" placeholder='Income Per Month*' name='income' id='income' /> : <></> }
      
      {employee == 's' ? <select onChange={handleChange} className='eligibilityCheck' required defaultValue="" name='credit' id='credit' >
        <option value=""  disabled hidden>Salary Credited In*</option>
        <option value="cash"> Cash </option>
        <option value="bank"> Bank Account </option>
      </select> : <></> }

     <Button type="submit" disabled={btnState} loading={btnLoading} > Check Eligibility </Button>

    </StyledPreApprovedForm>
  )
}

export default PreApprovedForm