import React, { useEffect } from 'react'
import WelcomeFeatures from '../../Welcome/WelcomeFeatures'
import { Route, Routes, useNavigate } from 'react-router-dom'
import PreApproved from '../../LoanType/PreApproved'
import LoanType from '../../LoanType/LoanType'
import EligibilityCheck from '../../LoanType/Eligibility/EligibilityCheck'
import EligibilitySuccess from '../../LoanType/Eligibility/EligibilitySuccess'
import EligibilityFailure from '../../LoanType/Eligibility/EligibilityFailure'
import SelectPlan from '../../LoanType/SelectPlan/SelectPlan'
import SelectedPlan from '../../LoanType/SelectedPlan/SelectedPlan'
import Progress from './Progress'

const WelcomeLayout = () => {

  const navigate = useNavigate();

  useEffect(()=>{
    if (sessionStorage.getItem('dmt') == null) {
      navigate('/')
    }
  },[])

  return (
    <div className='paddingHor'>
      <Progress/>
      <Routes>
        <Route index element={<> 
        <LoanType/>
        <WelcomeFeatures/> 
        </>} />

        <Route path='/approved' element={<PreApproved/>} />
        <Route path='/checkEligibility' element={<EligibilityCheck/>} />
        <Route path='/success' element={<EligibilitySuccess/>} />
        <Route path='/failure' element={<EligibilityFailure/>} />
        <Route path="/select" element={<SelectPlan/>} />
        <Route path="/subscribed" element={<SelectedPlan/>} />
      </Routes>
    </div>
  )
}

export default WelcomeLayout