import React from 'react'
import LoginForm from '../Forms/LoginForm'
import medicalLoan from '../../Assets/Common/medicalLoans.png'

const Login = () => {
  return (
    <div className='paddingHor' style={{textAlign: "center"}}>
        <img src={medicalLoan} alt="medical loans at 5%" width="300" />
        <LoginForm/>
    </div>
  )
}

export default Login