import React, { useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import { Pagination, Autoplay } from 'swiper/modules';
import { getSubscriptionList } from '../../APIs/APIFunctions';
import EligiblityPlanCard from './EligiblityPlanCard';
import styled from 'styled-components';

const StyledEligibilitySlider = styled.div`
  margin-top: 24px;
  .mySwiper{
    padding-bottom:24px;
  }
  .swiper-pagination{
    top: 90%;
  }
`

const EligibilityCheckSlider = () => {

  const [options, setOptions] = useState('');
  const [filtered, setFiltered] = useState('');

  async function getPLanList() {
    try {
      const data = await getSubscriptionList();
      console.log(data.data.msg.items, 'api');
      setOptions(prev => data.data.msg.items)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(()=>{
    getPLanList()
  },[])

  useEffect(()=>{
    if (options.length != 0) {
      let newArr = [];
      newArr = options.slice(0,3);
      newArr = newArr.reverse();
      setFiltered(prev => newArr)
      console.log(filtered);
    }
  },[options])

  return (
    <StyledEligibilitySlider>
      <Swiper autoplay={{ delay: 1000, disableOnInteraction: false}} pagination={true} modules={[Pagination, Autoplay]} className="mySwiper">
        { filtered.length != 0 ? 
          filtered.map((value, index)=>{
            return <SwiperSlide key={value.entity_code + index}>  
              <EligiblityPlanCard 
              interest={value.entity_details.entity_details.interest} 
              name={value.name}  
            />
            </SwiperSlide>
          })
        : <></> }
      </Swiper>
    </StyledEligibilitySlider>
  )
}

export default EligibilityCheckSlider
