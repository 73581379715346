import React, { useState } from 'react'
import styled from 'styled-components'
import successTick from '../../../Assets/Common/checkPassTick.png';
import successGraphic from '../../../Assets/Common/checkPassGraphic.png'
import Button from '../../Common/Button';
import { useNavigate } from 'react-router-dom';

const StyledSuccess = styled.div`
  padding: 0 20px;
  > img{
    width: 120px;
    margin: auto;
    display: block;
  }
  > div{
    margin: 16px 0 32px 0;
  }
  div img{
    width: 300px;
    display: block;
    margin: auto;
  }
`

const EligibilitySuccess = () => {

  const navigate = useNavigate();

  function handleNavigate() {
    console.log('click')
    navigate('/welcome/select');
  }

  return (
    <StyledSuccess>
      <img src={successTick} alt="eligibility passed checkmark" />
      <div>
        <img src={successGraphic} alt="approved loan graphic" />
      </div>
      <Button onClick={handleNavigate} > Buy Subscription </Button>
    </StyledSuccess>
  )
}

export default EligibilitySuccess