import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Button from '../Common/Button'
import { useLocation, useNavigate } from 'react-router-dom'
import { LoginApi, VerifyOtp, getApplicationDetails } from '../APIs/APIFunctions'
import loading from '../../Assets/Common/loading.svg'


const StyledForm = styled.form`
  > *:not(:last-child){
    margin: 16px 0;
  }
  label{
    font-size: 0.9rem;
  }
  .otpInputs{
    display:flex;
    gap: 16px;
    justify-content: space-between;
    margin: 8px 0;
  }
  .otpInputs input{
    text-align:center;
    max-width: 50px;
  }
  .resendBtn {
    display: flex;
    justify-content: space-between;
    align-items:center;
  }
  .resendBtn button{
    background:none;
    border:none;
    color: var(--blue);
    font-weight: 600;
    margin-bottom: -4px;
  }
  .resend .loading{
    animation-name: rotate;
    animation-duration: 0.6s;
    animation-iteration-count: infinite;
  }
  @keyframes rotate {
    from{
      rotate: 0deg;
    }
    to{
      rotate: 360deg;
    }
  }
`

function checkLength(value) {
  return value.value.length != 0
}

const OTPForm = () => {

  const [btnState, setBtnState] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);
  const [resendLoading, setResendLoading] = useState(false);
  const [otp, setOtp] = useState('');

  const location = useLocation();
  const navigate = useNavigate();

  function handleChange(e) {
    if (e.target.nextElementSibling != null && e.target.value.length === 1 ) {
      e.target.nextElementSibling.focus();
    }

    if(Array.from(document.querySelectorAll('input[name="otpInput"]')).every(checkLength)){
      setBtnState(prev => false);
      setOtp(prev => '');
      document.querySelectorAll('input[name="otpInput"]').forEach((value, index)=>{
        setOtp(prev => prev + value.value);
      })
    }
  }

  async function handleResend(e) {
    e.preventDefault();
    // setResendLoading(true);
    // try {
    //   const data = await LoginApi(mobile);
    //   console.log(data.data.msg.otp);
    // } catch (error) {
    //   console.log(error);
    // } finally{
    //   setResendLoading(false);
    // }
  }

  async function checkStatus() {
    const data = await getApplicationDetails();
    if (data.data.msg.application.status == 'lead') {
      console.log(data);
      navigate('/welcome');
    } else if (data.data.msg.application.status == 'application-added') {
      console.log(data);
      navigate('/welcome/subscribed');
    } else if (data.data.msg.application.status == 'eligibility-failed') {
      console.log(data);
      navigate('/welcome/failure');
    } else if (data.data.msg.application.status == 'eligibility-passed') {
      console.log(data);
      navigate('/welcome/success');
    } else{
      navigate('/welcome');
    }
  }

  async function handleSubmit(e) {
    e.preventDefault();
    setBtnLoading(true)
    try {
      const data = await VerifyOtp(location.state.number, otp);
      sessionStorage.setItem("dmt", data.data.msg.token);
      sessionStorage.setItem("aid", data.data.msg.application_id);
      console.log(data);
      checkStatus()
    } catch (error) {
      console.log(error);
    } finally {
      setBtnLoading(false);
    }
  }

  useEffect(()=>{
    sessionStorage.setItem('mbnum',location.state.number);
  },[location])

  return (
    <StyledForm onSubmit={handleSubmit}>
      <div>
        <label> Enter OTP </label>
        <div className='otpInputs'>
          <input type="text" maxLength="1" name="otpInput" id="otpInput1" onChange={handleChange} />
          <input type="text" maxLength="1" name="otpInput" id="otpInput2" onChange={handleChange} />
          <input type="text" maxLength="1" name="otpInput" id="otpInput3" onChange={handleChange} />
          <input type="text" maxLength="1" name="otpInput" id="otpInput4" onChange={handleChange} />
        </div>
        {/* <label className='resendBtn'> 
          Didn't receive your OTP? 
          <button className='resend' onClick={handleResend}> 
            { resendLoading ? <img src={loading} alt="" className="loading" width="10" /> : 'Resend OTP' }
          </button>
        </label> */}
      </div>
      <Button type="submit" disabled={btnState} loading={btnLoading}> Submit </Button>
    </StyledForm>
  )
}

export default OTPForm