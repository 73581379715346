import React from 'react'
import PlanCard from './PlanCard';

const PlanCardWrapper = (props) => {
  return (
    <>
      {
        props.data.map((value, index)=>{
          let interest = '';
          if (value.entity_code == "PLTM") {
            interest = 5;
          } else if (value.entity_code == "GOLD") {
            interest = 7;
          } else if (value.entity_code == "SLVR") {
            interest = 10;
          }
          if (value.entity_code == props.activePlan ) {
            return <PlanCard plan={value.entity_code} key={value.entity_code + index} amount={value.amount} interestNum={interest}  />
          } 
        })
      }
    </>
  )
}

export default PlanCardWrapper
