import React from 'react'
import PreApprovedForm from '../Forms/PreApprovedForm'
import styled from 'styled-components'
import eligibilityImage from '../../Assets/Common/checkEligibility.png'

const StyledPreApproved = styled.div`
  > img{
    display:block;
    margin: auto;
    margin-top: 40px;
  }
  h2{
    color: #1b3c6e;
    text-align:center;
    font-style: italic;
  }
  p{
    text-align:center;
    font-weight: 600;
    color: var(--blue);
  }
`

const PreApproved = () => {
  return (
    <StyledPreApproved>
      <h2> Please share your details </h2>
      <p> To check your eligibility for a <br/> Pre-Approved Medical Loan </p>
      <PreApprovedForm/>
      <img src={eligibilityImage} alt="details check graphic" width="150" />
    </StyledPreApproved>
  )
}

export default PreApproved