import axios from "axios";

let headers = {
  Accept: "application/json, text/plain, */*",
  "Accept-Language": "en-GB,en-US;q=0.9,en;q=0.8",
  "Content-Type": "application/json",
};

export async function LoginApi(mobile) {
  let data = JSON.stringify({
    data: [
      {
        key: "anchor",
        value: "AUPY",
      },
      {
        key: "mobile",
        value: mobile,
      },
    ],
  });

  let config = {
    method: "post",
    url: "https://sandbox-api.finverv.in/eci/apis/v2/auth/get-otp-generic/",
    headers: headers,
    data: data,
  };

  return await axios.request(config);
}

export async function VerifyOtp(mobile, otp) {
  let data = JSON.stringify({
    data: [
        {
            "key": "anchor",
            "value": "AUPY"
        },
        {
            "key": "mobile",
            "value": mobile
        },
        {
            "key": "otp",
            "value": otp
        }
    ],
  });

  let config = {
    method: "post",
    url: "https://sandbox-api.finverv.in/eci/apis/v2/auth/verify-and-signup-details/?onboarding_type=business_address",
    headers: headers,
    data: data,
  };

  return await axios.request(config);
}

export async function checkEligibility(employeeType, salaryMedium, monthlyIncome, pan, pincode, firstname, lastname, dob) {

  let headersCheck = {
    Accept: "application/json, text/plain, */*",
    "Accept-Language": "en-GB,en-US;q=0.9,en;q=0.8",
    Authorization: `Bearer ${sessionStorage.getItem('dmt')}`,
    "Content-Type": "application/json",
  };

  let data = JSON.stringify({
    data: [
      {
        "model_name": "trigger",
        "field": [
          {
            "field_type": "hidden",
            "key": "name",
            "value": "{\"page_number\": 1,\"trigger_names\": [\"application_status_eligibility_check\"] }"
          }
        ]
      },
      {
        "model_name": "borrower-employment",
        "field": [
          {
            "field_type": "dropdown",
            "key": "employment_type",
            "value": employeeType
          },
          {
            "field_type": "dropdown",
            "key": "medium_of_salary",
            "value": salaryMedium
          },
          {
            "field_type": "short-answer",
            "key": "monthly_income",
            "value": monthlyIncome
          }
        ]
      },
      {
        "model_name": "borrower",
        "field": [
          {
            "field_type": "short-answer",
            "key": "pan_number",
            "value": pan
          },
          {
            "field_type": "short-answer",
            "key": "first_name",
            "value": firstname
          },
          {
              "field_type": "short-answer",
              "key": "last_name",
              "value": lastname
          },
          {
              "field_type": "date",
              "key": "date_of_birth",
              "value": dob
          }
        ]
      },
      {
        "model_name": "borrower-resident-address",
        "field": [
          {
            "field_type": "short-answer",
            "key": "pincode",
            "value": pincode
          }
        ]
      }
    ],
  });

  let config = {
    method: "post",
    url: `https://sandbox-api.finverv.in/v3/apis/webapi/applications/${sessionStorage.getItem('aid')}/save/data/`,
    headers: headersCheck,
    data: data,
  };

  return await axios.request(config);
}

export async function getApplicationDetails() {

  let headersCheck = {
    Accept: "application/json, text/plain, */*",
    "Accept-Language": "en-GB,en-US;q=0.9,en;q=0.8",
    Authorization: `Bearer ${sessionStorage.getItem('dmt')}`,
    "Content-Type": "application/json",
  };

  let config = {
    method: "get",
    url: `https://sandbox-api.finverv.in/v3/apis/webapi/applications/${sessionStorage.getItem('aid')}/view/data/?allowed=borrower%2Capplication%2Cborrower-employment%2Capplicationloanamount%2Cborrower-bank-doc%2Cborrower-employment%2Capplication_entity_relationship_current_hospital%2Capplication_entity_relationship_prefered_hospital%2Capplication_entity_relationship_prefered_hospital_appointment`,
    headers: headersCheck
  };

  return await axios.request(config);
}

export async function getSubscriptionList() {

  let headersCheck = {
    Accept: "application/json, text/plain, */*",
    "Accept-Language": "en-GB,en-US;q=0.9,en;q=0.8",
    Authorization: `Bearer ${sessionStorage.getItem('dmt')}`,
    "Content-Type": "application/json",
  };

  let config = {
    method: "get",
    url: `https://sandbox-api.finverv.in/v3/apis/webapi/entity/subscription/list/`,
    headers: headersCheck
  };

  return await axios.request(config);
}

export async function setSubscription(subId) {

  let headersCheck = {
    Accept: "application/json, text/plain, */*",
    "Accept-Language": "en-GB,en-US;q=0.9,en;q=0.8",
    Authorization: `Bearer ${sessionStorage.getItem('dmt')}`,
    "Content-Type": "application/json",
  };

  let data = JSON.stringify({
    "data": [
      {
          "model_name": "application_entity_relationship_subscription",
          "field": [
              {
                  "field_type": "radio",
                  "key": "entity",
                  "value": subId
              }
          ]
      }
    ]
  });

  let config = {
    method: "post",
    url: `https://sandbox-api.finverv.in/v3/apis/webapi/applications/${sessionStorage.getItem('aid')}/save/data/`,
    headers: headersCheck,
    data: data,
  };

  return await axios.request(config);
}

export async function addSubscription() {

  let headersCheck = {
    Accept: "application/json, text/plain, */*",
    "Accept-Language": "en-GB,en-US;q=0.9,en;q=0.8",
    Authorization: `Bearer ${sessionStorage.getItem('dmt')}`,
    "Content-Type": "application/json",
  };

  let data = JSON.stringify({
    "data": [
      {
          "model_name": "application",
          "field": [
              {
                field_type: "short-answer",
                key: "status",
                value: "application-added"
              }
          ]
      }
    ]
  });

  let config = {
    method: "post",
    url: `https://sandbox-api.finverv.in/v3/apis/webapi/applications/${sessionStorage.getItem('aid')}/save/data/`,
    headers: headersCheck,
    data: data,
  };

  return await axios.request(config);
}

export async function getSelectedPlan() {

  let headersCheck = {
    Accept: "application/json, text/plain, */*",
    "Accept-Language": "en-GB,en-US;q=0.9,en;q=0.8",
    Authorization: `Bearer ${sessionStorage.getItem('dmt')}`,
    "Content-Type": "application/json",
  };

  let config = {
    method: "get",
    url: `https://sandbox-api.finverv.in/v3/apis/webapi/applications/${sessionStorage.getItem('aid')}/view/data/?allowed=application_entity_relationship_subscription`,
    headers: headersCheck
  };

  return await axios.request(config);
}

export async function createOrder(amount) {
  let data = JSON.stringify({
    "application_id": sessionStorage.getItem('aid'),
    "eservice": "pg-razorpay",
    "amount": amount,
    "name": "Ayushpay",
    "image": "https://finverv.s3.amazonaws.com/documents/ayushpy-logo-image.jpg",
    "key": "rzp_test_Me092IVDqsmT2R",
    "color": "#039de5"
  });

  let config = {
    method: 'post',
    url: 'https://sandbox-api.finverv.in/finverv/internal/v2/pg_razorpay_order_create/',
    headers: headers,
    data: data
  }

  return await axios.request(config);
}

export async function initRazorPay(amount) {
  let data = JSON.stringify({
    "application_id": sessionStorage.getItem('aid'),
    "eservice": "pg-razorpay",
    "amount": amount,
    "name": "Ayushpay",
    "image": "https://finverv.s3.amazonaws.com/documents/ayushpy-logo-image.jpg",
    "key": "rzp_test_Me092IVDqsmT2R",
    "color": "#039de5"
  });

  let config = {
    method: 'post',
    url: 'https://sandbox-api.finverv.in/finverv/internal/v2/pg_razorpay_order_create/',
    headers: headers,
    data: data
  }

  return await axios.request(config);
}

export async function verifyRazorPay(paymentId) {
  let data = JSON.stringify({
    "payment_status": "success",
    "payment_id": paymentId,
    "eservice_call_id": sessionStorage.getItem('eci')
  });

  let config = {
    method: 'post',
    url: 'https://sandbox-api.finverv.in/finverv/internal/v2/pg_razorpay_order_verify/',
    headers: headers,
    data: data
  }

  return await axios.request(config);
}
