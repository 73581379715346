import React from 'react'
import { Route, Routes } from 'react-router-dom'
import LoginLayout from './Layouts/LoginLayout'
import WelcomeLayout from './Layouts/Welcome/WelcomeLayout'

const Wrapper = () => {
  return (
    <>
      <div className='maxWidth'>
        <Routes>
          <Route path='*' element={<LoginLayout/>} />
          <Route path="/welcome/*" element={<WelcomeLayout/>} />
        </Routes>
      </div>
    </>
  )
}

export default Wrapper