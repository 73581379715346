import React from "react";
import styled from "styled-components";
import ayushLogo from '../../Assets/Common/ayushpayLogo.png'
import turtlemint from '../../Assets/Common/turtleMint.png'
import { useLocation, useNavigate } from "react-router-dom";
import backIcon from '../../Assets/Common/backIcon.svg'

const StyledNavbar = styled.div`
    margin-bottom: 24px;
    padding: 8px 0;
    > div{
        display:flex;
        justify-content:space-between;
        align-items:center; 
    }
    > div > div{
        display:flex;
    }
`

const Navbar = () => {

    const location = useLocation();
    const navigate = useNavigate();

    function handleNavigation(e) {
        navigate(-1);
    }

    return <StyledNavbar>
        <div  className="maxWidth paddingHor">
            <div>
                { location.pathname != '/' ? <img src={backIcon} onClick={handleNavigation} alt="go back" height="30" /> : <></> }
                <img src={ayushLogo} alt="ayushpay" height="30" />
            </div>
            <img src={turtlemint} alt="ayushpay" height="40" />
        </div>
    </StyledNavbar>;
};

export default Navbar;
