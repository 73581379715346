import React from 'react'
import styled from 'styled-components'
import affordable from '../Assets/Common/affordableIcon.png'
import instant from '../Assets/Common/instantIcon.png'
import partners from '../Assets/Common/partnersIcon.png'

const StyledFeatures = styled.div`
    margin-top: 24px;
    background: var(--blue);
    padding: 20px 0;
    *{
        color:white;
        text-align:center;
    }
    h3{
        text-transform:uppercase;
    }
    .featureLine p{
        text-align:left;
        max-width: 260px;
        margin:auto;
        padding: 16px 0;
        display:flex;
        align-items:center;
        gap: 16px;
        font-weight: 600;
        font-style: italic;
    }
    .featureLine p:not(:last-child){
        border-bottom: 1px solid white;
    }
`

const Features = () => {
    return (
        <StyledFeatures className=''>
            <div className='paddingHor'>
                <h3> We Offer </h3>
                <div className='featureLine'>
                    <p> <img src={affordable} alt="affordable loan" width="50" /> Sab se sasta loan </p>
                    <p> <img src={instant} alt="fast loan" width="50" /> Instant Loan Approval </p>
                    <p> <img src={partners} alt="partners hospital" width="50" /> 10,000+ Partner Hospitals </p>
                </div>
            </div>
        </StyledFeatures>
    )
}

export default Features