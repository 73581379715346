import React from 'react'
import Button from '../../Common/Button'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import checkFail from '../../../Assets/Common/checkFailed.png'

const StyledFailure = styled.div`
  padding: 0 20px;
  h2{
    margin: 8px 0 24px 0;
  }
  button{
    background:#d91817;
  }
  img{
    margin: auto;
    display: block;
  }
`

const EligibilityFailure = () => {

  const navigate = useNavigate();

  function handleNavigation(e) {
    sessionStorage.removeItem('dmt');
    sessionStorage.removeItem('aid');
    navigate('/');
  }

  return (
    <StyledFailure>
      <img src={checkFail} alt="eligibility failed graphic" width="100" />
      <h2 className='blueHeading italic'> Sorry! <br/> Currently we are unable to process your pre-approved medical loan </h2>
      <Button onClick={handleNavigation} disabled={false} type="button" > Exit </Button>
    </StyledFailure>
  )
}

export default EligibilityFailure
