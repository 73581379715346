import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import infoIcon from '../../Assets/Common/infoIcon.png'

const StyledLoanCard = styled.div`
  position: relative;
  background: #f8fafb;
  box-shadow: 0 0 30px -3px lightgrey;
  padding: 24px;
  border-radius: 16px;
  max-width: 400px;
  margin: auto;
  img {
    display:block;
    margin:auto;
    margin-bottom: 16px;
  }
  .infoIcon{
    position:absolute;
    right: 8px;
    top: 8px;
  }
`

const LoanCard = (props) => {

  function handleClick(e) {
    if (props.name == "approved") {
      props.showInfo('approved')
    } else if (props.name == "instant") {
      props.showInfo('instant') 
    }
    document.querySelector('.loanWrapper .overlay').style.display = 'flex';
  }

  return (
    <StyledLoanCard className='loanCard'>
      <img src={infoIcon} onClick={handleClick} alt="i icon" className='infoIcon' width="30" />
      <img src={props.image} alt="" width="250" />
      <Link to={props.path} className='primaryBtn'> {props.buttonText} </Link>
    </StyledLoanCard>
  )
}

export default LoanCard