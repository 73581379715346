import React from 'react'
import styled from 'styled-components'

const StyledPlanCard = styled.div`
  background: linear-gradient(135deg, rgba(1, 27, 101, 1) 40%, rgba(102, 4, 185, 1) 100%);
  padding: 16px;
  padding: 32px 16px 28px 16px;
  border-radius: 8px 72px 0 0;
  h4{
    color:white;
    font-weight: 500;
    margin-bottom: 16px;
  }
  h3{
    font-weight: 500;
  }
  h2,h3{
    color: #fff302;
  }
`

const EligiblityPlanCard = (props) => {
  return (
    <StyledPlanCard>
      <h4> {props.name} </h4>
      <h3> Get Medical Loan </h3>
      <h2> @{props.interest}% Interest </h2>
    </StyledPlanCard>
  )
}

export default EligiblityPlanCard
