import React from 'react'
import styled from 'styled-components'
import cashIcon from '../../Assets/Common/cashIcon.png'
import instantWhite from '../../Assets/Common/instantIconWhite.png'
import easyIcon from '../../Assets/Common/easyIcon.png'

const StyledFeatures = styled.div`
    display:flex;
    justify-content:space-around;
    > div{
        text-align:center;
        display:flex;
        flex-direction:column;
        align-items:center;
        font-weight: 600;
        line-height: 125%;
        gap: 4px;
    }
`

const WelcomeFeatures = () => {
  return (
    <StyledFeatures>
        <div>
            <img src={cashIcon} alt="cash graphic" width="40" height="40" />
            Get up to 15 lakhs
        </div>
        <div>
            <img src={instantWhite} alt="cash graphic" width="40" height="40" />
            Instant Approval
        </div>
        <div>
            <img src={easyIcon} alt="cash graphic" width="40" height="40" />
            Easy Repayments
        </div>
    </StyledFeatures>
  )
}

export default WelcomeFeatures