export async function startRazor(mobile="7291020638", amount, orderId, handlerFunc ) {

  var options = {
      "key": "rzp_test_Me092IVDqsmT2R", // Enter the Key ID generated from the Dashboard
      "amount": amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      "currency": "INR",
      "name": "Ayush Pay", //your business name
      "description": "Test Transaction",
      "image": "https://example.com/your_logo",
      "order_id": orderId, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
      "handler": function (res) {
        console.log(res);
        handlerFunc(res);
      },
      "prefill": { //We recommend using the prefill parameter to auto-fill customer's contact information, especially their phone number
          "name": "Gaurav Kumar", //your customer's name
          "email": "gaurav.kumar@example.com", 
          "contact": mobile  //Provide the customer's phone number for better conversion rates 
      },
      "notes": {
          "address": "Razorpay Corporate Office"
      },
      "theme": {
          "color": "#3399cc"
      }
  };

  var rzp1 = new window.Razorpay(options);
  rzp1.on('payment.failed', function (response){
      alert(response.error.code);
      alert(response.error.description);
      alert(response.error.source);
      alert(response.error.step);
      alert(response.error.reason);
      alert(response.error.metadata.order_id);
      alert(response.error.metadata.payment_id);
  });

  rzp1.open();
}

// document.getElementById('razorBtnClass').onclick = function(e){
//     rzp1.open();
//     e.preventDefault();
// }

// function (response){
//   console.log(response, 'inside handlerFunc');
//     alert(response.razorpay_payment_id);
//     alert(response.razorpay_order_id);
//     alert(response.razorpay_signature);
// }

