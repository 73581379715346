import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { addSubscription, createOrder, getSubscriptionList, setSubscription, verifyRazorPay } from '../../APIs/APIFunctions'
import Button from '../../Common/Button'
import { useNavigate } from 'react-router-dom'
import SelectPlanRadios from './SelectPlanRadios'
import PlanCardWrapper from './PlanCardWrapper'
import { startRazor } from '../../APIs/RazorPayFunc'

const StyledOptions = styled.div`
  margin-top: 64px;
  .planSelect{
    display: flex;
    justify-content: space-around;
    gap: 8px;
    padding-left: 16px;
  }
  a{
    color: var(--blue);
    text-decoration: underline;
    font-size: 0.6rem;
    display: block;
    text-align:right;
    margin-top: 8px;
  }
  .best{
    position: absolute;
    top: -21px;
    background: red;
    color: white;
    padding: 2px 7px 13px 7px;
    right: 7px;
    z-index: -1;
    font-size: 0.8rem;
    border-radius: 8px 8px 0 0;
    font-weight: 600;
  }
  button{
    margin-top: 24px;
  }
`

const SelectPlanOptions = () => {

  const [options, setOptions] = useState('');
  const [filtered, setFiltered] = useState('');
  const [activePlan, setActivePlan] = useState('PLTM');
  const [btnLoading, setBtnLoading] = useState(false);
  const navigate = useNavigate();

  async function fetchOptions() {
    try {
      const data = await getSubscriptionList();
      console.log(data.data.msg.items, 'api')
      setOptions(prev => data.data.msg.items)
    } catch (error) {
      console.log(error)
    }
  }

  async function addAndNavigate() {
    setBtnLoading(true);
    try {
      const data = await addSubscription();
      console.log(data);
      navigate('/welcome/subscribed');
    } catch (error) {
      console.log(error)
    } finally{
      setBtnLoading(false);
    }
  }

  async function handleSelectPlan(e) {
    // e.preventDefault();
    let subId = document.querySelector('input:checked').getAttribute('aid');
    setBtnLoading(true);
    try {
      const data = await setSubscription(subId);
      console.log(data);
      addAndNavigate();
    } catch (error) {
      console.log(error);
    } finally{
      setBtnLoading(false);
    }
  }

  async function checkPayment(paymentId) {
    try {
      const data = await verifyRazorPay(paymentId);
      console.log('verify api', data);
      handleSelectPlan()
    } catch (error) {
      console.log(error)
    }
  }

  async function getRazorData(data) {
    console.log(data, 'get data');
    checkPayment(data.razorpay_payment_id);
  }

  async function handleRazor(e) {
    e.preventDefault();
    setBtnLoading(true);

    try {
      const data = await createOrder(Number(document.querySelector('input:checked').getAttribute('amount')));
      console.log('order create',data);
      sessionStorage.setItem('roid',data.data.msg.response_body.id);
      sessionStorage.setItem('eci',data.data.msg.eservice_call_id);
      startRazor(sessionStorage.getItem('mbnum'),Number(document.querySelector('input:checked').getAttribute('amount')),sessionStorage.getItem('roid'), getRazorData);
    } catch (error) {
      console.log(error) 
    }
  }

  useEffect(()=>{
    fetchOptions();
  },[])

  useEffect(()=>{
    if (options.length != 0) {
      let newArr = [];
      newArr = options.slice(0,3);
      newArr = newArr.reverse();
      setFiltered(prev => newArr)
    }
  },[options])

  return (
    <StyledOptions>
      <div className='planSelect'>
        { filtered.length != 0 ? <SelectPlanRadios data={filtered} activePlan={activePlan} changeActive={setActivePlan} /> : <></> }
      </div>

      { filtered.length != 0 && activePlan.length != 0 ? <PlanCardWrapper activePlan={activePlan} data={filtered} /> : <></> }
      <a href='https://www.ayushpay.com/termsCondition'> *Terms and conditions apply </a>

      {/* <Button onClick={handleSelectPlan} loading={btnLoading}> Buy Subscription </Button> */}
      <Button onClick={handleRazor} loading={btnLoading}> Buy Subscription </Button>
    </StyledOptions>
  )
}

export default SelectPlanOptions
