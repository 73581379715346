import React from "react";
import Features from "../Features";
import Login from "../Login/Login";
import OTP from "../Login/OTP";
import { Route, Routes } from "react-router-dom";

const LoginLayout = () => {
  return (
    <div style={{height: "calc(100vh - 80px)", display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
      <Routes>
        <Route index element={<Login/>} />
        <Route path="/verify" element={<OTP />} />
      </Routes>
      <Features />
    </div>
  );
};

export default LoginLayout;
