import React, { useEffect, useState } from "react";
import Button from "../Common/Button";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { LoginApi } from "../APIs/APIFunctions";

const StyledForm = styled.form`
  padding: 0 16px;
  margin: 24px 0;
  text-align:left;
  > *:not(:last-child) {
    margin-bottom: 16px;
  }
  a{
    color: #415c86;
    text-decoration: underline;
    font-weight: 500;
  }
  .mobileField {
    display:flex;
  }
  .mobileField span{
    display:inline-block;
    background: var(--blue);
    border-radius: 8px 0 0 8px;
    margin-right: -8px;
    z-index: 1;
    color: white;
    padding: 5px 8px 4px 8px;
  }
  input[name="mobile"]{
    padding-left: 16px;
  }
  input[type="checkbox"]{
    position:relative;
    min-width: 16px;
    height: 16px;
    top: 2px;
  }
  label {
    color: #666666;
    font-size: 0.9rem;
    width: 100%;
    display:flex;
    align-items: flex-start;
    gap: 8px;
  }
`;

const LoginForm = () => {

  const navigate = useNavigate();
  const [btnState, setBtnState] = useState(true);
  const [mobile, setMobile] = useState('');
  const [checkTerms, setCheckTerms] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);

  async function handleSubmit(e) {
    e.preventDefault();
    setBtnLoading(true);

    try {
      const data = await LoginApi(mobile);
      console.log(data.data.msg.otp);
      navigate("/verify", { state: { number: mobile } }); 
    } catch (error) {
      console.log(error);
    } finally{
      setBtnLoading(false);
    }

  }

  function handleChange(e) {
    if (e.target.id === 'mobile') {
      setMobile((prev) => {
        if (e.target.value.length < 11) {
          return e.target.value;
        } else {
          return prev;
        }
      });
    }
    if (e.target.id === 'terms') {
      setCheckTerms(prev => !prev);
    }
  }

  useEffect(()=>{
    if (mobile.length === 10 && checkTerms) {
      setBtnState(prev => false)
    } else{
      setBtnState(prev => true);
    }
  },[mobile, checkTerms])

  return (
    <StyledForm onSubmit={handleSubmit}>
      
      <div className="mobileField">
        <span>
          +91
        </span>
        <input name="mobile" value={mobile} onChange={handleChange} required type="number" id="mobile" placeholder="Enter Mobile No. to apply" />
      </div>
      <Button disabled={btnState} loading={btnLoading}> Get OTP </Button>

      <div>
        <label>
          <input
            required
            type="checkbox"
            name="terms and conditions"
            id="terms"
            onChange={handleChange}
            checked={checkTerms}
          />
          <div>
            By proceeding, you agree to <a href="https://www.ayushpay.com/termsCondition"> AyushPay's Terms & Conditions </a> & <a href="https://www.ayushpay.com/privacyPolicy"> Privacy Policy </a>
          </div>
        </label>
      </div>

      <div>
        <label>
          <input type="checkbox" name="whatsapp updates" id="whatsappUpdates" />
          I agree to receive updates on whatsapp
        </label>
      </div>
    </StyledForm>
  );
};

export default LoginForm;
