import React, { Fragment, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import Journey from '../../../Data/Journeys.json';
import styled from 'styled-components';
import tick from '../../../Assets/Common/navTick.svg'

const StyledProgress = styled.div`
  display: flex;
  justify-content: space-evenly;
  font-size: 10px;
  margin-bottom: 24px;
  margin-top: -8px;
  color: #1b3c6e;
  font-weight: 700;
  gap: 8px;
  margin-left: -8px;
  margin-right: -8px;
  position: relative;
  &::before{
    content: '.';
    position: absolute;
    width: 80%;
    height: 13px;
    margin: auto;
    border-bottom: 0px solid black;
    font-size: 0;
    border-bottom-style: dotted;
    z-index: -1;
    background-image: linear-gradient(to right, black 33%, rgba(255, 255, 255, 0) 0%);
    background-position: bottom;
    background-size: 5px 1px;
    background-repeat: repeat-x;
  }
  > div{
    width: 20%;
    text-align:center;
    opacity: 0.8;
  }
  div.current, div.active{
    opacity: 1;
  }
  div.current .circle{
    border-color: var(--blue);
    color: var(--blue);
  }
  div.active .circle{
    color:white;
    background: #0ce88d;
    border-color: #0ce88d;
    font-size:0;
  }
  .circle{
    margin:auto;
    width: 24px;
    height: 24px;
    border: 1px solid lightgrey;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 4px;
    background:white;
  }
  .circle img{
    display:none;
  }
  div.active .circle img{
    display: block;
  }
`

const Progress = () => {

  let check = true;

  const location = useLocation();

  useEffect(()=>{
    console.log(location);
  },[location])

  return (
    <StyledProgress>
      {
        Journey[0].paths.map((value, index)=>{
        let classname = '';
        if (value.path != location.pathname && check) {
          classname = "active";
        } else if (location.pathname == '/welcome/subscribed') {
          classname = "active";
        } else if(value.path == location.pathname){
          classname = 'current';
          check = false;
        } else{
          classname = "";
        }
        console.log(classname)
        return <Fragment key={index}>
          { value.display ? <div className={classname} >
          <div className='circle'>
            {value.step}
            <img src={tick} alt="" />
          </div>
          {value.text} 
        </div> : <></> }
        </Fragment> 
      })}
    </StyledProgress>
  )
}

export default Progress
