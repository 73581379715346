import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import waitImage from '../../../Assets/Common/wait.png'
import { getApplicationDetails } from '../../APIs/APIFunctions'
import { useNavigate } from 'react-router-dom'
import EligibilityCheckSlider from './EligibilityCheckSlider'

const StyledEligibiliyCheck = styled.div`
  > img{
    display: block;
    margin: 16px auto;
  }
  p{
    text-align:center;
    font-weight: 600;
    color: var(--blue);
  }
  p.big{
    font-size: 1.2rem;
    font-weight: 700;
  }
`


const EligibilityCheck = () => {

  let checkInterval = '';

  const [eligibility, setEligibility] = useState('');
  const navigate = useNavigate();  

  async function getDetails() {
    const data = await getApplicationDetails();
    if (data.data.msg.application.status != 'lead') {
      console.log(data);
      stopInterval(checkInterval);
      setEligibility(data.data.msg.application.status)
    }
    return data;
  }

  function stopInterval(params) {
    clearInterval(checkInterval)
  }

  useEffect(()=>{
    if (checkInterval.length == 0) {
      checkInterval = setInterval(getDetails, 2000);
    }
  },[])

  useEffect(()=>{
    if (eligibility.length != 0) {
      if (eligibility == 'eligibility-passed') {
        navigate('/welcome/success');
      } else if (eligibility == 'eligibility-failed') {
        navigate('/welcome/failure');
      } else {
        navigate(-1);
      }
    }
  },[eligibility])

  return (
    <StyledEligibiliyCheck>
      <h2 className='blueHeading italic'> Please wait while we are <br/> checking your eligibility </h2>
      <img src={waitImage} alt="waiting graphic" width="150" />
      <p> Buy a plan to avail your </p>
      <p className='big'> pre-approved medical loan </p>
      <EligibilityCheckSlider  />
    </StyledEligibiliyCheck>
  )
}

export default EligibilityCheck